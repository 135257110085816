<!-- eslint-disable no-useless-escape -->
<script>

import 'codemirror/theme/material.css';
import 'codemirror/mode/htmlmixed/htmlmixed.js';
import 'codemirror/addon/hint/show-hint';
import 'codemirror/addon/hint/html-hint';
import 'codemirror/addon/display/autorefresh.js';
import 'codemirror/mode/css/css.js';
import debounce from 'lodash.debounce';
import CodeMirror from 'codemirror';
import Variables from "@/components/personalizations/variables";

import templateHandlebar from '../../helpers/templates'
export default {
  data() {
		return {
      htmlCode: '',
      cssCode: '',
      viewport: 'desktop',
      htmlCodeMirror: null,
      cssCodeMirror: null,
      showVariables:false,
      variables:[],
      indexTab:0,
      selectedText:'',
      buttonPosition: { top: 0, left: 0 },
      showPreviewURL:false,
      reservedVars:[
        "gs_variantId",
        "gs_experienceId",
        "gs_personalizationId",
        "#recommendations items",
        "/recommendations",
        "gs_recoStrategy",
        "gs_recoCount",
        "gs_recoStrategyAffinity",
        "gs_recommendations",
        "gs_recoStrategyCustom",
        "gs_templateId",
      ],
      url:null,

      x:0,
      y:0,
      leftSide:0,
      rightSide:0,
      leftWidth:0,
      resizer:null,
      groups:[],
      isDevMode: false,
      mode: 'design',
      styleSheetURL: '',
      styleSheetsURL:[],
      templateId:null
    }
  },
  components:{Variables},
 name: 'CustomWebBuilder',
  props:{
    html: String,
    items: Array,
    affinity_Types: Array,
    categories: Array,
    variant: Object,
    devMode: Boolean  
  },
  computed:{
    showConvertButton : function() {
      if(this.selectedText && this.selectedText!='' && this.selectedText.trim().length > 2 && this.reservedVars.filter(v=>v.toUpperCase() == this.selectedText.trim().toUpperCase()).length == 0){
        return true;
      }
      
      return false
    }
  },
  watch:{
    devMode : {
      immediate: true,
      handler(newVal){
        this.isDevMode = newVal;
      }
    }
  },
 mounted(){
  this.initializeCodeMirror();

  const vm = this;
  document.body.addEventListener('click', function (evt) {

    if (evt.target.className.includes('cm-variables')) {
        
      const name =  evt.target.textContent.trim();
      if(vm.reservedVars.filter(r=> r.toUpperCase() == name.toUpperCase()).length ==0){
        vm.indexTab = 0;
        if(vm.variables.filter(v=>v.name.toUpperCase() == name.toUpperCase()).length==0){
          let variable = {
            name,
            options:[],
            type:null,
          }
          vm.$refs.variablesComponent.onNewVariable(variable)
        }else{
          vm.$refs.variablesComponent.onVariableSettingClicked(vm.variables.filter(v=>v.name == name)[0])
        }
      }
    }
  }, false);

  this.onSetMode('design');
 },
 methods:{
  ...templateHandlebar,

  extractHTMLRecommendationItemText(text) {
    const regex = /{{#recommendations items}}(.*?){{\/recommendations}}/gis;
    const matches = [];
    let match;

    while ((match = regex.exec(text)) !== null) {
      matches.push(match[1]);
    }

    return matches;
},

previewRecommendations(){
    
    if(this.variables.filter(v=> v.type.id == 'gs_recoCount').length > 0 && this.variables.filter(v=> v.type.id == 'gs_recoCount')[0].value){

      const startTag = '{{#recommendations items}}';
      const endTag = '{{\/recommendations}}';
      const regex = new RegExp(`${startTag}(.*?)${endTag}`, 'gs');

      let replicatedHTML = this.htmlCode.replace(regex, (match, capturedText) => {
      
      const recoCount = this.variables.filter(v => v.type.id === 'gs_recoCount')[0].value;
      let replicatedText = '';

      for (let i = 0; i < this.items.length  && i < recoCount ; i++) {
        let modifiedText = capturedText;
        const placeholders = capturedText.match(/{{(.*?)}}/g);

        if (placeholders) {
          placeholders.forEach(placeholder => {
            
            const placeholderKey = placeholder.trim().slice(2, -2); // Remove the curly braces
            const filteredVariables = this.variables.filter(v=> v.type.id == 'product_property' && v.name == placeholderKey);
            
            if(filteredVariables.length > 0){
              if(filteredVariables[0].value.id == 'image'){
                const placeholderValue = this.items[i].data['imgs'].length > 0 ? this.items[i].data['imgs'][0].url : 'dale' ;
                modifiedText = modifiedText.replace(placeholder, placeholderValue);
              }else{
                const placeholderValue = this.items[i].data[filteredVariables[0].value?.id];
                modifiedText = modifiedText.replace(placeholder, placeholderValue);
              }
            }
          });
        }

        replicatedText += modifiedText;
      }

      return replicatedText;
    });
    return replicatedHTML;
      }
      
    return this.htmlCode;
},

  getRecoItems(){
    let itemsArray = []
    if(this.items.length > 0 /*&& this.variables.filter(v => v.type.id === 'gs_recoCount').length > 0 */){
      const recoCount = this.variables.filter(v => v.type.id === 'gs_recoCount').length > 0 ? this.variables.filter(v => v.type.id === 'gs_recoCount')[0].value : 10;
      itemsArray = this.items.slice(0, recoCount).map(obj => (obj.data));

      itemsArray.forEach(i=> i.image_url = i.imgs.length > 0 ? i.imgs[0].url : '');
    }
    
    return itemsArray;
  },
  shouldAddRecommendationsVariables(){

    const go_recoIndex = this.getCaseInsensitiveIndex(this.htmlCode, '{{#recommendations items}}');
    const end_go_reco_Index = this.getCaseInsensitiveIndex(this.htmlCode, '{{\/recommendations}}');

    if((go_recoIndex!=-1 && end_go_reco_Index!=-1 ) && this.variables.filter(v=>v.type.id == "gs_recoStrategy").length == 0 &&  this.variables.filter(v=>v.type.id == "gs_recoCount").length == 0) {
      this.variables.push(
        {
          name: this.$t('personalizations.experience_variable_strategy'),
          type: {id:'gs_recoStrategy'},
          placeholder: this.$t('personalizations.experience_variable_strategy_placeholder'),
          tooltip: this.$t('personalizations.experience_variable_strategy_tooltip'),
        },
        {
          name: this.$t('personalizations.experience_variable_strategy_count'),
          type: {id:'gs_recoCount'},
          tooltip: this.$t('personalizations.experience_variable_strategy_count_tooltip'),
          placeholder: this.$t('personalizations.experience_variable_strategy_count_placeholder'),
        }
      );
      this.$refs.variablesComponent.setVariables(this.variables);
      this.$refs.variablesComponent.setGroups(this.groups);

    }else if(go_recoIndex == -1 || end_go_reco_Index == -1){
      this.variables = this.variables.filter(v=> v.type.id != 'gs_recoStrategy' && v.type.id != 'gs_recoCount' );
      this.$refs.variablesComponent.setVariables(this.variables);
    }
  },
  initializeCodeMirror() {
        
    

        this.htmlCodeMirror = CodeMirror.fromTextArea(this.$refs.htmlTextArea, {
        mode: 'htmlmixed',
          theme: 'material',
          lineNumbers: true,
          lineWrapping: true,
          value: this.htmlCode,
          viewportMargin: Infinity,
          autorefresh: true,
          tabSize: 2, 
          indentUnit: 2,
        });
        this.htmlCodeMirror.refresh();

        this.htmlCodeMirror.on('change', (cm, change) => this.updatePreview('html',cm, change));
        this.htmlCodeMirror.on('keydown', (cm, event)=> this.onEditorKeydown(cm,event));
        this.htmlCodeMirror.on('cursorActivity', this.debouncedHandleCursorActivityHtml);

        this.cssCodeMirror = CodeMirror.fromTextArea(this.$refs.cssTextArea, {
          mode:'text/css',
          theme: 'material',
          lineNumbers: true,
          lineWrapping: true,
          value: this.cssCode,
          viewportMargin: Infinity,
          autorefresh: true,
          tabSize: 2, 
          indentUnit: 2,
        });
        this.cssCodeMirror.refresh();

        this.cssCodeMirror.on('change', (cm) => this.updatePreview('css',cm));
        this.cssCodeMirror.on('keydown', (cm, event)=> this.onEditorKeydown(cm,event));
        this.cssCodeMirror.on('cursorActivity', this.debouncedHandleCursorActivityCss);
    },
    
    debouncedHandleCursorActivityHtml: debounce(function() {
      this.handleCursorActivity('html');
    }, 300),
   
    debouncedHandleCursorActivityCss: debounce(function() {
      this.handleCursorActivity('css');
    }, 300),

    handleCursorActivity(type) {

      if(!type)
        return;
      let editorPos;
      let cursorPos;
      if(type == 'html'){
        this.selectedText = this.htmlCodeMirror.getSelection();
        editorPos = this.htmlCodeMirror.getWrapperElement().getBoundingClientRect();
        cursorPos = this.htmlCodeMirror.cursorCoords(true, 'page');
      }else if(type == 'css'){
        this.selectedText = this.cssCodeMirror.getSelection();
        editorPos = this.cssCodeMirror.getWrapperElement().getBoundingClientRect();
        cursorPos = this.cssCodeMirror.cursorCoords(true, 'page');
      }  
      
      if(this.reservedVars.filter(r=> r == this.selectedText).length > 0 || this.variables.filter(v=> v.name == this.selectedText).length > 0){
        this.selectedText = null;
        return;
      }

      const buttonTop = cursorPos.top - editorPos.top - 10; 
      const buttonLeft = cursorPos.left - editorPos.left - 10; 

      this.buttonPosition = {
        top: `${buttonTop}`,
        left: `${buttonLeft}`,
      };
    },
    onEditorKeydown(cm, event){
      var cursor = cm.getCursor();
      var lineContent = cm.getLine(cursor.line);
      var currentChar = lineContent.charAt(cursor.ch - 1);

      if (event.key === '{' && currentChar === '{') {
        cm.replaceRange('{}}', cursor, cursor);
        cm.setCursor(cursor.line, cursor.ch + 1);

        event.preventDefault(); 
      }
    },
    checkRecoVariable(change){
      const newText = this.htmlCodeMirror.getValue(); // Get the entire text
      const searchIndex = this.getCaseInsensitiveIndex(newText, '{{#recommendations items}}');

      if (searchIndex !== -1) {
        const endGoRecoText = '{{\/recommendations}}';
        const startPos = { line: change?.from?.line, ch: searchIndex + '{{#recommendations items}}'.length };
        const cursorPos = this.htmlCodeMirror.posFromIndex(searchIndex + '{{#recommendations items}}'.length + Math.floor(endGoRecoText.length / 2));

        if (!this.isEndRecoInserted(newText, startPos)) {
          const updatedText = newText.slice(0, startPos.ch) + endGoRecoText + newText.slice(startPos.ch);

          this.htmlCodeMirror.setValue(updatedText);
          this.htmlCodeMirror.setCursor(cursorPos);
        }
      }
    },
    getCaseInsensitiveIndex(text, searchText) {
      const searchRegex = new RegExp(searchText, 'i');
      const match = text.match(searchRegex);
      return match ? match.index : -1;
    },
    isEndRecoInserted(text, startPos) {
      const endRecoText = '{{\/recommendations}}';
      const endRecoIndex = text.indexOf(endRecoText, startPos.ch);
      return endRecoIndex !== -1;
    },
    markText(cm) {
      cm.operation(() => {
        cm.getAllMarks().forEach((mark) => mark.clear());

        const content = cm.getValue();
        const pattern = /\{{([^}]*)}}/g;
        let match;
      
        while ((match = pattern.exec(content)) !== null) {
          const startPos = match.index + 2;
          const endPos = pattern.lastIndex - 2;
          
          let cla = content.substring(startPos, endPos)?.toUpperCase() == 'GO_RECO' || content.substring(startPos, endPos)?.toUpperCase() == 'END_GO_RECO' ? 'cm-reco' : 'cm-variables' ;
          cm.markText(
            cm.posFromIndex(startPos),
            cm.posFromIndex(endPos),
            { className: cla }
          );
        }
      });
    },
    updatePreview(type,cm, change) {
      switch(type){
        case 'html':
          this.htmlCode = this.htmlCodeMirror.getValue();
          break;
        case 'css':
          this.cssCode = this.cssCodeMirror.getValue();
          break;
      }
      if(cm)
        this.markText(cm);

        this.shouldAddRecommendationsVariables();
        this.checkRecoVariable(change)

      const previewFrame = this.$refs.previewFrame;
      try{
        const content = this.generatePreviewHTML();
        previewFrame?.setAttribute('srcdoc', content);
      }catch(ex){
        // eslint-disable-next-line no-console
        console.log(ex);
      }
    },
    generatePreviewHTML() {
      return `
        <html>
          <head>
            <meta name="viewport" width=device-width, height=device-height, initial-scale=1.0, maximum-scale=1.0, user-scalable=0>
            <style>${this.renderEmailTemplate(this.cssCode, this.variables, {
                gs_recommendations: this.getRecoItems(),
                gs_templateId: this.templateId || 'templateId'
              })}</style>
          </head>
          <body style="overflow-y: scroll !important;overflow: hidden; padding: 0; margin: 0;">
            ${this.renderEmailTemplate(this.htmlCode, this.variables, {
                gs_recommendations: this.getRecoItems(),
                gs_templateId: this.templateId || 'templateId'
              })}
          </body>
        </html>
      `.trim();
    },
    getExportedHTML(){
      return ` <!-- begin_recommender(${this.templateId}) -->
      <style>${this.renderEmailTemplate(this.cssCode, this.variables, {
          gs_recommendations: this.getRecoItems(),
          gs_templateId: this.templateId || 'templateId'
        })}</style>
      ${this.renderEmailTemplate(this.htmlCode, this.variables, {
              gs_recommendations: this.getRecoItems(),
              gs_templateId: this.templateId || 'gs_TemplateId'
            })}
            <!-- end_recommender -->`
    },
  getTemplatesValues(){
      return {
        html: this.htmlCode,
        exportedHTML: this.getExportedHTML(),
        css: this.cssCode,
        type: 'reco_email',
        variables: this.$refs.variablesComponent.getVariables(),
        groups: this.$refs.variablesComponent.getGroups()
      }
    },
    setTemplateValue(values, templateId = 'templateId'){
      this.htmlCode = values.html || '';
      this.cssCode = values.css || '';

      // eslint-disable-next-line no-console
      console.log(values);

      this.htmlCodeMirror.setValue(this.htmlCode);
      this.cssCodeMirror.setValue(this.cssCode);

      this.$refs.variablesComponent.setVariables(values.variables || [])
      this.$refs.variablesComponent.setGroups(values.groups || [])
      this.updateVariables(values.variables || []);
      this.updateGroups(values.groups || []);

      this.templateId = templateId;
      // eslint-disable-next-line no-console
      console.log(this.templateId);

    },
    updateVariables(variables){
      this.variables = variables;
      this.updatePreview();
    },
    updateGroups(groups){
      this.groups = groups;
    },
    onTabClicked(type){
      this.selectedText = '';
      switch(type){
        case 'html':
          setTimeout(()=>{
            this.htmlCodeMirror.focus();
            this.htmlCodeMirror.refresh();
          },100)
          break;
        case 'css':
        setTimeout(()=>{
            this.cssCodeMirror.focus();
            this.cssCodeMirror.refresh();
          },100)
          break;
      }
    },
    onConvertToVariableClicked(){
      this.indexTab = 0;
      let variable = {
          name: this.selectedText,
          options:[]
        }
        this.$refs.variablesComponent.onNewVariable(variable)
    },
    
    onResolutionChanged(viewport){
      this.viewport = viewport;
      if(viewport== 'desktop'){
        document.querySelector('.iframe-container').classList.remove('viewport-mobile');
        document.querySelector('.iframe-container').classList.remove('viewport-tablet');
        document.querySelector('.iframe-container').classList.add('viewport-desktop');
      }else if(viewport == 'tablet'){
        document.querySelector('.iframe-container').classList.remove('viewport-mobile');
        document.querySelector('.iframe-container').classList.remove('viewport-desktop');
        document.querySelector('.iframe-container').classList.add('viewport-tablet');
      }else if(viewport== 'mobile'){
        document.querySelector('.iframe-container').classList.remove('viewport-desktop');
        document.querySelector('.iframe-container').classList.remove('viewport-tablet');
        document.querySelector('.iframe-container').classList.add('viewport-mobile');
      }
      this.updatePreview();
    },

    showCodeSection(show=false){
      const codeTabs = document.querySelector('div.initial-vars > div > div:nth-child(1) > ul').children;
      if(!show){
        codeTabs.forEach((c,index)=>{
          if(index > 0)
            c.classList.add('d-none');
        })
      }else{
        codeTabs.forEach((c,index)=>{
          if(index > 0)
            c.classList.remove('d-none');
        })
      }
    },
    onSetMode(mode){
      
      this.mode = mode;
      this.indexTab=0;
      if(mode == 'design'){
        const loader = this.$loading.show();
        setTimeout(()=>{
          this.showCodeSection(false);
          this.onResolutionChanged(this.viewport);
          loader.hide();
        }, 1000);
      }else{
        this.showCodeSection(true)
      }
    },
  }
}
</script>

<template>
  
  <div class="row">
    <div v-if="mode=='design'" :class="isDevMode ? 'initial-preview-dev-mode' :'initial-preview' " style="min-width: 30%; max-width:79%" :style="{ width : isDevMode ? '30%' : '72%'}">
      <div class="iframe-container viewport-desktop">
        <iframe ref="previewFrame" class="preview-iframe" id="myIframe"></iframe>
      </div>
    </div>
    <div :class="isDevMode ? 'initial-vars-dev-mode' :'initial-vars' " style="min-width: 20%; flex: 1 1 0%;">
      <b-tabs v-model="indexTab" justified nav-class="nav-tabs-custom" content-class="p-3 text-muted">
        <b-tab active>
          <template v-slot:title>
            <span class="d-inline-block d-sm-none">
              Variables
            </span>
            <span class="d-none d-sm-inline-block"> <i class="mdi mdi-code-json"></i> VARIABLES</span>
          </template>
          <div class="code-editor">
            <Variables ref="variablesComponent" @updateVariables="updateVariables" @updateGroups="updateGroups" :affinity_Types="affinity_Types" :pageType="'email'" />
          </div>
        </b-tab>
        <b-tab v-on:click="onTabClicked('html')" ref="tabHTML" class="coso">
          <template v-slot:title>
            <span class="d-inline-block d-sm-none">
              HTML
            </span>
            <span class="d-none d-sm-inline-block"> <i class="mdi mdi-language-html5"></i> HTML</span>
          </template>
          <div class="code-editor">
            <textarea ref="htmlTextArea"></textarea>
          </div>
          <div class="button-container" v-if="showConvertButton" :style="{ top: buttonPosition.top + 'px', left: buttonPosition.left + 'px' }">
              <button class="btn btn-link btn-md" @click="onConvertToVariableClicked">{{$t('personalizations.experience_variables_convert')}}</button>
          </div>
        </b-tab>
        <b-tab v-on:click="onTabClicked('css')" v-if="!email" ref="tabCSS">
          <template v-slot:title>
            <span class="d-inline-block d-sm-none">
              Css
            </span>
            <span class="d-none d-sm-inline-block"> <i class="mdi mdi-brush"></i> CSS</span>
          </template>
          <div class="code-editor">
            <textarea ref="cssTextArea"></textarea>
          </div>
          <div class="button-container" v-if="showConvertButton" :style="{ top: buttonPosition.top + 'px', left: buttonPosition.left + 'px' }">
              <button class="btn btn-link btn-md" @click="onConvertToVariableClicked">{{$t('personalizations.experience_variables_convert')}}</button>
          </div>
        </b-tab>
      </b-tabs>
    </div>
  </div>
</template>

<style>
.code-editor {
  width: 100%;
  height: 600px;
  margin-bottom: 10px;
}

.preview {
  width: 100%;
  height: 600px;
}

.preview-iframe{
  width: 100%;
  height: 768px;
  display: block;
  margin: auto;

  
}

.iframe-container {
  box-shadow: 0 2px 2px 0 rgb(0 0 0 / 10%), 0 2px 10px 0 rgb(0 0 0 / 18%);
  height: 768px; /* Ajusta la altura según tus necesidades */
  transform-origin: 0 0; /* Transformar desde la esquina superior izquierda */
  overflow: hidden; /* Ocultar cualquier contenido que se salga del contenedor */
  margin-top: 15px !important;
  margin-bottom: 15px !important;
}

.CodeMirror{
  height: 600px !important;
}

.interact {
  cursor: pointer;
}

.cm-variables{
  background-color: rgba(241,180,76,.25)!important;
  cursor: pointer;
}

.cm-reco{
  background-color: rgba(195, 191, 206, 0.359)!important;
}

.button-container {
  position: absolute;
  background-color: white;
  padding: 5px;
  border: 1px solid black;
}

.initial-preview-dev-mode{
  width: 30% ;
}

.initial-preview{
  width: 64%;
  background-color: #f8f8fb;
}

.initial-vars-dev-mode{
  width: 69%;;
}

.initial-vars{
  width: 35%;
}

.viewport-desktop{
  width: 1280px;
  transform: scale(0.75);
}

.viewport-tablet{
  width: 768px;
  margin: auto;
}

.viewport-mobile{
  width: 375px;
  margin: auto;
}

.hidden {
  display: none !important;
}
</style>

